import React, { Component } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import classNames from "classnames";
import {
  Card,
  Typography,
  CardContent,
  CardHeader,
  CardActions
} from "@material-ui/core";
import { getIntl } from "../../utils";
import TextButton from "../reusable/TextButton";
import LocalizedText from "../reusable/LocalizedText";
import {
  updateConnectNowLicense,
  insertConnectNowLicense,
  removeConnectNowLicense
} from "./actions";
import { LockOpen, Lock } from "@material-ui/icons";
import CTXTextField from "../reusable/CTXTextField";

const styles = theme => ({
  root: {
    width: "500px",
    height: "375px",
    display: "flex",
    flexDirection: "column",
    margin: "20px",
    backgroundColor: theme.colors.popoverBackgroundColor
  },
  cardHeader: {
    padding: "16px 40px 5px 40px"
  },
  cardHeaderTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  cardContent: {
    padding: "0 40px",
    flexGrow: 1
  },
  cardActions: {
    paddingLeft: "40px",
    paddingRight: "40px",
    flexDirection: "row-reverse"
  },
  scrollBar: {
    "&::-webkit-scrollbar": {
      width: "8px",
      height: "8px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.colors.secondaryMainColor,
      borderRadius: "4px",
      border: "none"
    },
    "&::-webkit-scrollbar-track-piece": {
      background: theme.colors.sideBarBackgroundColor,
      borderRadius: "4px"
    },
    "&::-webkit-scrollbar-corner": {
      background: theme.colors.sideBarBackgroundColor
    },
    scrollbarColor: `${theme.colors.secondaryMainColor} ${theme.colors.popoverBackgroundColor}`,
    scrollbarWidth: "thin"
  },
  displayFlexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  licenseDisabledContainer: {
    padding: "10px 40px",
    justifyContent: "center",
    height: "100%"
  },
  licenseDetailsContainer: {
    padding: "10px 40px",
    gap: "15px"
  },
  displayFlexRow: {
    display: "flex",
    flexDirection: "row"
  },
  alignItemsCenter: {
    alignItems: "center"
  },
  licenseDetailsEntryControls: {
    justifyContent: "space-between",
    gap: "30px"
  },
  invalidBorder: {
    borderStyle: "solid",
    borderColor: theme.colors.errorMainColor
  },
  disabledBorder: {
    borderStyle: "solid",
    borderColor: theme.colors.secondaryTextColor
  },
  buttonMargin: {
    marginLeft: "10px"
  },
  buttonRoot: {
    minWidth: "120px",
    minHeight: "33px"
  },
  textFieldContainer: {
    height: "100%"
  },
  inputColor: {
    color: theme.colors.primaryTextColor
  },
  licenseDisabledTextColor: {
    color: theme.colors.secondaryTextColor
  },
  errorTextColor: {
    color: theme.colors.errorTextColor
  },
  justifyContentCenter: {
    justifyContent: "center"
  }
});

class LicenseCard extends Component {
  constructor() {
    super();
    this.state = { isEditMode: false, isDeleteMode: false, encryption: "" };
    this.textFieldInputRef = React.createRef();
  }

  //Card header
  getCardHeaderControl = () => {
    const { classes, valid } = this.props;

    const headerAvatar = valid ? <LockOpen /> : <Lock />;

    return (
      <CardHeader
        avatar={headerAvatar}
        title={this.getCardHeaderTitleControl()}
        titleTypographyProps={{ color: "inherit", variant: "h5" }}
        classes={{
          root: classes.cardHeader
        }}
      />
    );
  };

  getCardHeaderTitleControl = () => {
    const { classes } = this.props;

    return (
      <div className={classes.cardHeaderTitle}>
        <LocalizedText
          value="details"
          className={
            this.props.encryption.length === 0
              ? classes.licenseDisabledTextColor
              : null
          }
          variant="h6"
          color="inherit"
          inline
        />
        {this.props.encryption.length !== 0 && !this.props.valid && (
          <LocalizedText value="licenseInvalid" inline color="error" />
        )}
      </div>
    );
  };

  //Card Content
  getCardContentControl = () => {
    const { classes } = this.props;

    return (
      <CardContent
        classes={{
          root: classes.cardContent
        }}
      >
        {this.getCardContent()}
      </CardContent>
    );
  };

  getCardContent = () => {
    const { classes, intl, features } = this.props;

    return this.state.isEditMode ? (
      <div className={classes.textFieldContainer}>
        <CTXTextField
          multiline
          fullWidth
          rows={7}
          variant="outlined"
          autoFocus
          inputRef={this.textFieldInputRef}
          InputProps={{
            classes: {
              input: classNames(classes.inputColor, classes.scrollBar)
            }
          }}
          value={this.state.encryption}
          onChange={event => {
            this.setState({ encryption: event.target.value });
          }}
        />
      </div>
    ) : this.state.isDeleteMode ? (
      <div
        className={classNames(
          classes.displayFlexRow,
          classes.textFieldContainer,
          classes.alignItemsCenter,
          classes.justifyContentCenter
        )}
      >
        <LocalizedText value="doYouWantToDeleteLicense" variant="subtitle1" />
      </div>
    ) : this.props.encryption.length === 0 ? (
      <div
        className={classNames(
          classes.displayFlexColumn,
          classes.licenseDisabledContainer
        )}
      >
        <LocalizedText
          className={classes.licenseDisabledTextColor}
          value="licenseDisabled"
          variant="h6"
        />
      </div>
    ) : (
      <div
        className={classNames(
          classes.displayFlexColumn,
          classes.licenseDetailsContainer
        )}
      >
        {this.getLicenseDetailsEntryControls(
          intl.formatMessage(getIntl("expire")),
          this.props.expire
            ? this.props.expire
            : intl.formatMessage(getIntl("na"))
        )}
        {this.getLicenseDetailsEntryControls(
          intl.formatMessage(getIntl("mac")),
          this.props.MAC ? this.props.MAC : intl.formatMessage(getIntl("na"))
        )}
        {this.getLicenseDetailsEntryControls(
          intl.formatMessage(getIntl("video")),
          features.videoEnabled
            ? intl.formatMessage(getIntl("enabled"))
            : intl.formatMessage(getIntl("disabled"))
        )}
        {this.getLicenseDetailsEntryControls(
          intl.formatMessage(getIntl("transcription")),
          features.transcription.enabled
            ? intl.formatMessage(getIntl("enabled"))
            : intl.formatMessage(getIntl("disabled"))
        )}
        {this.getLicenseDetailsEntryControls(
          intl.formatMessage(getIntl("maxTranscriptionTranslationLanguages")),
          features.transcription.maxTranslationLanguages
        )}
        {this.getLicenseDetailsEntryControls(
          intl.formatMessage(getIntl("poweredByCompunetixLogo")),
          features.poweredByLogoDisplayed
            ? intl.formatMessage(getIntl("displayed"))
            : intl.formatMessage(getIntl("hidden"))
        )}
      </div>
    );
  };

  getLicenseDetailsEntryControls = (labelName, value) => {
    const { classes } = this.props;

    return (
      <div
        className={classNames(
          classes.displayFlexRow,
          classes.alignItemsCenter,
          classes.licenseDetailsEntryControls
        )}
      >
        <Typography variant="body1" color="inherit">
          {labelName}
        </Typography>
        <div className={classes.valueTypography}>
          <Typography variant="body1" color="inherit">
            {value}
          </Typography>
        </div>
      </div>
    );
  };

  //Card actions
  getCardActionsControl = () => {
    const { classes } = this.props;
    const { isEditMode, isDeleteMode } = this.state;

    const actionControls =
      isEditMode || isDeleteMode ? (
        <div>
          <TextButton
            className={classes.buttonMargin}
            classes={{ root: classes.buttonRoot }}
            onClick={this.handleEditOrDeleteConfirm}
          >
            <LocalizedText value="ok" />
          </TextButton>
          <TextButton
            className={classes.buttonMargin}
            classes={{ root: classes.buttonRoot }}
            onClick={() => {
              this.setState({
                isEditMode: false,
                isDeleteMode: false,
                encryption: this.props.encryption
              });
            }}
          >
            <LocalizedText value="cancel" />
          </TextButton>
        </div>
      ) : (
        <div>
          <TextButton
            className={classes.buttonMargin}
            classes={{ root: classes.buttonRoot }}
            onClick={() => {
              this.setState({ encryption: this.props.encryption });
              this.setState({ isEditMode: true, isDeleteMode: false });
            }}
          >
            <LocalizedText value="edit" />
          </TextButton>
          {this.props.encryption.length !== 0 && (
            <TextButton
              className={classes.buttonMargin}
              classes={{ root: classes.buttonRoot }}
              color="error"
              onClick={() => {
                this.setState({ isEditMode: false, isDeleteMode: true });
              }}
            >
              <LocalizedText
                value="delete"
                className={classes.errorTextColor}
              />
            </TextButton>
          )}
        </div>
      );

    return (
      <CardActions
        disableSpacing
        classes={{
          root: classes.cardActions
        }}
      >
        {actionControls}
      </CardActions>
    );
  };

  //functions
  handleEditOrDeleteConfirm = () => {
    const { session } = this.props;
    const { portalSessionId } = session;
    const { encryption, isDeleteMode } = this.state;

    if (isDeleteMode) {
      this.handleDeleteConfirm();
    } else {
      if (encryption == null || encryption.length === 0) {
        this.textFieldInputRef.current.focus();
        return;
      }

      if (this.props.encryption.length !== 0) {
        this.props.updateConnectNowLicense(portalSessionId, encryption);
      } else {
        this.props.insertConnectNowLicense(portalSessionId, encryption);
      }
    }

    this.setState({ isEditMode: false, isDeleteMode: false });
  };

  handleDeleteConfirm = () => {
    const { session } = this.props;
    const { portalSessionId } = session;

    this.props.removeConnectNowLicense(portalSessionId, this.props.title);
  };

  render() {
    const { classes } = this.props;

    return (
      <Card
        className={classNames(
          classes.root,
          this.props.encryption.length !== 0 &&
            !this.props.valid &&
            classes.invalidBorder,
          this.props.encryption.length === 0 && classes.disabledBorder
        )}
      >
        {this.getCardHeaderControl()}
        {this.getCardContentControl()}
        {this.getCardActionsControl()}
      </Card>
    );
  }
}

const mapStateToProps = ({ session }) => ({ session });

const mapDispatchToProps = {
  updateConnectNowLicense,
  insertConnectNowLicense,
  removeConnectNowLicense
};

export default withTheme(
  withStyles(styles)(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(LicenseCard))
  )
);
