import { Component } from "react";
import { connect } from "react-redux";
import io from "socket.io-client";
import {
  updateParticipants,
  updateBufferedParticipants,
  updateConference,
  updateUsers,
  userLogin,
  logoutUser,
  websocketReconnect,
  stopSharing,
  updateChats,
  updateChatReadState,
  updateUserPriority,
  updateConferenceState,
  sendPortalKeepalive,
  setDataQAState,
  updateDataQAEntry,
  removeDataQAEntry,
  updateNotification,
  setSharingUserId,
  setHasHostJoined,
  setUserID,
  setVideoInput,
  setWebCamOn,
  setWebCamOff,
  handleParticipantApiRequest,
  loginUser,
  setConnectPopperExpansion,
  setCMDialInToken,
  addTranscript,
  setPartialTranscript,
  addTranslation,
  setPartialTranslation
} from "../../actions";
import { NotificationType, NotificationLevel } from "../notification";
import {
  getFullscreenElement,
  exitFullscreen,
  isHoldStateApplicable,
  isVideoEnabled,
  isCM
} from "../../utils";
import { withRoomContext } from "../mediasoup/RoomContext";
import * as cookiesManager from "../mediasoup/cookiesManager";
import Logger from "../../Logger";
import CMSocket from "./CMSocket";

const logger = new Logger("WebSocketHandler");

const participantDataInactivityTimeout = 100;
const participantDataStarvationTimeout = 1000;
const pingTimeout = 30000;

class WebSocketHandler extends Component {
  constructor(props) {
    super(props);
    this.ws = null;
    this.pingTimer = null;
    this.socketId = null;
    this.audioConf = null;
    this.audioConfRecordingCode = null;
    this.guid = null;
    this.pollingTimer = null;

    if (isCM()) {
      window.gCnHandler = {};

      window.gCnHandler.handleCMUserLogin = data => {
        this.props.userLogin(data);
      };

      window.gCnHandler.handleCMHasHostJoined = () => {
        this.props.setHasHostJoined();
      };

      window.gCnHandler.handleCMDialInToken = data => {
        this.props.setCMDialInToken(data);
      };
    }
  }

  participantProcessing = {
    buffers: {
      1: [],
      2: [],
      3: []
    },
    activeBuffer: 1,
    inactiveTimer: undefined,
    insertData: function (data, processData) {
      if (this.buffers[this.activeBuffer].length === 0) {
        setTimeout(() => {
          this.processBuffer(processData);
        }, participantDataStarvationTimeout);
      }
      clearTimeout(this.inactiveTimer);
      this.inactiveTimer = setTimeout(() => {
        this.processBuffer(processData);
      }, participantDataInactivityTimeout);
      this.buffers[this.activeBuffer].push(data);
    },
    processBuffer: function (processData) {
      const previousBuffer = this.changeActiveBuffer();
      const bufferedData = [...this.buffers[previousBuffer]];
      this.buffers[previousBuffer] = [];
      processData(bufferedData);
    },
    changeActiveBuffer: function () {
      const previousBuffer = this.activeBuffer;
      this.activeBuffer =
        this.activeBuffer === Object.keys(this.buffers).length
          ? 1
          : this.activeBuffer + 1;
      return previousBuffer;
    }
  };

  handleParticpantData = data => {
    this.participantProcessing.insertData(
      data,
      this.props.updateBufferedParticipants
    );
    this.props.updateParticipants(data);
  };

  handleResponse = response => {
    const { history } = this.props;

    if (response) {
      history.replace("/main?vi=" + response);
    } else {
      history.replace("/login");
    }
  };

  handleMuteParticipant = () => {
    this.props.handleParticipantApiRequest(
      this.props.session.partyID,
      this.props.session.userId,
      "mute"
    );
  };

  handleWebSocketConnection = userId => {
    let socket;

    if (isCM()) {
      socket = new CMSocket();
    } else {
      socket = io(this.props.uri, {
        reconnectionAttempts: 5,
        transports: ["websocket"]
      });
    }

    socket.on("connect", () => {
      logger.debug("socket connect");
      this.socketId = socket.id;
      socket.emit("userID", userId);
    });

    socket.on("userID", userID => {
      this.props.setUserID(userID);
    });

    socket.on("party", data => {
      this.handleParticpantData(data);
    });

    socket.on("conference", data => {
      this.props.updateConference(data);
      if (data.active && !this.audioConf) {
        this.audioConf = true;
        this.audioConfRecordingCode = data.recordingCode;
      } else if (!data.active && this.audioConf) {
        this.audioConf = false;
        this.audioConfRecordingCode = null;
      }
    });

    socket.on("conferenceState", data => {
      if (data !== null) {
        this.guid = data.guid.substring(0, 32);

        const { session, roomClientProvider } = this.props;

        if (
          session.remoteControllerMe &&
          data.remoteControllerID !== session.id &&
          data.sharingActive
        ) {
          this.props.updateNotification({
            level: NotificationLevel.WARNING,
            type: NotificationType.REMOTE_CONTROL_DISABLED
          });
        }

        if (session.isSharer) {
          if (!session.remoteControlActive && data.remoteControllerID !== 0) {
            roomClientProvider.startControl();
          } else if (
            session.remoteControlActive &&
            data.remoteControllerID === 0
          ) {
            roomClientProvider.stopControl();
          }
        }

        if (!isCM()) {
          data.hostDialout = true;
          data.confLink = data.persistentSpaceName;
        }

        let isHostView =
          data.sharerID !== 0 && data.sharerID === this.props.websocket.id;
        this.props.updateConferenceState(data, isHostView);

        if (data.sharerID !== 0) {
          this.props.setSharingUserId(data.sharerID);
        } else {
          this.props.stopSharing(data);
          this.props.setSharingUserId(0);
        }

        if (data.dataQAActive != null) {
          this.props.setDataQAState(data.dataQAActive);
        }

        if (data.hasHostJoined) {
          this.props.setHasHostJoined();
        }
      }
    });

    socket.on("user", data => {
      //TODO handle logout
      this.props.updateUsers(data);
    });

    //happens if try to log in with invalid session id
    socket.on("disconnectReason", data => {
      logger.debug("socket disconnect - %s", data);
      //TODO handle this correctly with messages and/or different page
      if (socket.id === this.socketId) {
        this.handleCloseMediaRoom();
        this.handleWebRTCCallEnd();

        const { history, session } = this.props;
        this.props.logoutUser(
          history,
          session.persistentSpaceName,
          session.userRole,
          session.vettingConfig
        );

        const isRedirectURLEnabled =
          session.redirectURL !== null &&
          session.redirectURL !== undefined &&
          session.redirectURL.trim().length !== 0;

        if (isRedirectURLEnabled) {
          window.location = session.redirectURL;
        }

        //exit full screen
        if (getFullscreenElement()) {
          exitFullscreen();
        }
        //set notification
        let notificationObj = null;
        switch (data) {
          case "conferenceEnd":
            notificationObj = {};
            notificationObj.level = NotificationLevel.SUCCESS;
            notificationObj.type = NotificationType.CONFERENCE_END;
            break;
          case "hostForceRemoval":
            notificationObj = {};
            notificationObj.level = NotificationLevel.WARNING;
            notificationObj.type = NotificationType.HOST_FORCE_REMOVAL;
            break;
          default:
        }
        if (notificationObj != null) {
          this.props.updateNotification(notificationObj);
        }
      }
    });

    socket.on("transfer", data => {
      logger.debug("socket transfer");
      //TODO handle this correctly with messages and/or different page
      if (socket.id === this.socketId) {
        this.handleCloseMediaRoom();
        this.handleWebRTCCallEnd();

        const { history, session } = this.props;
        this.props.logoutUser(history, null);

        const { login } = this.props;
        const formValues = {
          passcode: data,
          username: session.username
        };
        login(formValues, response => this.handleResponse(response));

        //exit full screen
        if (getFullscreenElement()) {
          exitFullscreen();
        }
        //set notification
        let notificationObj = null;
        switch (data) {
          case "conferenceEnd":
            notificationObj = {};
            notificationObj.level = NotificationLevel.SUCCESS;
            notificationObj.type = NotificationType.CONFERENCE_END;
            break;
          case "hostForceRemoval":
            notificationObj = {};
            notificationObj.level = NotificationLevel.WARNING;
            notificationObj.type = NotificationType.HOST_FORCE_REMOVAL;
            break;
          case "remoteControlEnabled":
            notificationObj = {};
            notificationObj.level = NotificationLevel.WARNING;
            notificationObj.type = NotificationType.REMOTE_CONTROL_ENABLED;
            break;
          default:
        }
        if (notificationObj != null) {
          this.props.updateNotification(notificationObj);
        }
      }
    });

    socket.on("reconnect", () => {
      logger.debug("socket reconnect");
      this.props.websocketReconnect();
    });

    socket.on("reconnect_failed", () => {
      logger.debug("socket reconnect_failed");
      //TODO handle this correctly with messages and/or different page
      this.handleCloseMediaRoom();
      this.handleWebRTCCallEnd();

      const { history, session } = this.props;
      this.props.logoutUser(
        history,
        session.persistentSpaceName,
        session.userRole,
        session.vettingConfig
      );
    });

    socket.on("ping", () => {
      clearTimeout(this.pingTimer);
      this.pingTimer = setTimeout(sendPing, pingTimeout);
    });

    socket.on("chat", data => {
      if (this.props.websocket.id === data.senderId) {
        this.props.updateChatReadState(data.id, data.senderId, true);
      } else if (data.receiverId === 0) {
        this.props.updateChatReadState(data.id, 0, false);
      } else {
        this.props.updateUserPriority(data.senderId);
        this.props.updateChatReadState(data.id, data.senderId, false);
      }
      this.props.updateChats(data);
    });

    socket.on("data_qa", data => {
      this.props.updateDataQAEntry(data);
    });

    socket.on("data_qa_remove", data => {
      this.props.removeDataQAEntry(data);
    });

    socket.on("notification", data => {
      this.props.updateNotification(data);

      if (
        (data.type === NotificationType.DISABLE_PRESENTER_MODE ||
          data.type === NotificationType.STOP_SCREEN_SHARE) &&
        data.level === NotificationLevel.WARNING
      ) {
        this.props.roomClientProvider.disableShare();
      }
    });

    socket.on("mouse", mouseEvent => {
      this.props.roomClientProvider.controlMouse(
        mouseEvent[0],
        mouseEvent[1],
        mouseEvent[2],
        mouseEvent[3]
      );
    });

    socket.on("keyboard", keyboardEvent => {
      this.props.roomClientProvider.controlKeyboard(
        keyboardEvent[0],
        keyboardEvent[1],
        keyboardEvent[2]
      );
    });

    socket.on("transcript", data => {
      this.props.addTranscript(data);
    });

    socket.on("partialTranscript", data => {
      this.props.setPartialTranscript(data);
    });

    socket.on("translation", translation => {
      this.props.addTranslation(translation);
    });

    socket.on("partialTranslation", translation => {
      this.props.setPartialTranslation(translation);
    });

    const sendPing = () => {
      logger.debug("sending ping");
      socket.emit("");
      this.pingTimer = setTimeout(sendPing, pingTimeout);
    };

    this.ws = socket;
  };

  sendMouseEvent = (uMsg, wParam, x, y) => {
    this.ws.emit("mouse", [uMsg, wParam, x, y]);
  };

  sendKeyboardEvent = (uMsg, wParam, lParam) => {
    this.ws.emit("keyboard", [uMsg, wParam, lParam]);
  };

  handleCloseMediaRoom = () => {
    this.props.roomClientProvider.close();
  };

  handleWebRTCCallEnd = () => {
    if (window.webRTCSession) {
      window.webRTCSession.terminate();
    }
  };

  componentWillUnmount() {
    if (this.ws) {
      this.ws.disconnect();
    }
  }

  pollPortal = () => {
    this.props
      .sendPortalKeepalive(this.props.websocket.portalSessionID)
      .then(() => {
        this.pollingTimer = setTimeout(this.pollPortal, 10000);
      })
      .catch(() => {
        clearTimeout(this.pollingTimer);
        this.pollingTimer = null;
      });
  };

  getDevices = async () => {
    try {
      let stream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: this.props.session.videoInput,
          width: 1280,
          height: 720
        }
      });
      stream.getTracks().forEach(track => {
        track.stop();
      });
    } catch (error) {
      logger.error("getDevices error: %o", error);
    }

    try {
      const videoInput = await this.listVideoInputDevices();

      const webcamEnabled =
        cookiesManager.getDevices() != null
          ? cookiesManager.getDevices().webcamEnabled
          : this.props.session.webcamEnabled;

      const { roomClientProvider } = this.props;

      if (webcamEnabled && navigator.mediaDevices.getUserMedia) {
        this.props.setWebCamOn();

        await roomClientProvider.disableWebcam();
        if (videoInput !== "" && videoInput !== "default") {
          await roomClientProvider.enableWebcam(videoInput);
        }
      } else {
        cookiesManager.setDevices({ webcamEnabled: false });
        this.props.setWebCamOff();

        await roomClientProvider.disableWebcam();
      }
    } catch (error) {
      logger.error("getDevices error: %o", error);
    }
  };

  listVideoInputDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();

    let videoInputDevices = [];
    for (var i = 0; i < devices.length; i++) {
      var device = devices[i];
      if (device.kind === "videoinput") {
        videoInputDevices.push(device);
      }
    }

    let newDeviceId;
    if (this.props.session.videoInput === "default") {
      if (cookiesManager.getVideoInput() == null) {
        newDeviceId = videoInputDevices[0].deviceId;
        logger.debug("Update cookie & session videoInput to: %s", newDeviceId);
        this.props.setVideoInput(newDeviceId);
        cookiesManager.setVideoInput({
          videoInputDeviceId: newDeviceId
        });
      } else {
        newDeviceId = cookiesManager.getVideoInput().videoInputDeviceId;
        logger.debug("Update session videoInput to: %s", newDeviceId);
        this.props.setVideoInput(newDeviceId);
      }
      return newDeviceId;
    } else {
      return this.props.session.videoInput;
    }
  };

  componentDidUpdate(prevProps) {
    const { session, conference, room, roomClientProvider } = this.props;
    const prevSession = prevProps.session;
    const { id, userId, guid, vetted, cmLoggedIn } = session;

    if (
      (!prevSession.guid ||
        !prevSession.vetted ||
        (isCM() && !prevSession.cmLoggedIn) ||
        isHoldStateApplicable(prevProps.conference, prevProps.session)) &&
      guid &&
      vetted &&
      !isHoldStateApplicable(conference, session) &&
      (!isCM() || cmLoggedIn)
    ) {
      roomClientProvider.init(id, userId, guid, this);
    }

    if (
      (prevProps.room.state !== "connected" ||
        !isVideoEnabled(prevProps.session.videoConfig)) &&
      room.state === "connected" &&
      isVideoEnabled(session.videoConfig)
    ) {
      this.getDevices();
    }

    if (prevSession.vetted === false && vetted) {
      this.props.setConnectPopperExpansion(true);
    }

    if (
      !window.CtxAppConfigurations.audioConnectionControlVisible &&
      prevSession.mergedCallState !== "disconnected" &&
      session.mergedCallState === "disconnected"
    ) {
      // The setTimeout is also needed in order for the ConnectPopper to still
      // receive the first popper expansion update and not batch the updates.
      setTimeout(() => {
        // Reconnect the WebRTC call
        this.props.setConnectPopperExpansion(false);
        this.props.setConnectPopperExpansion(true);
      }, 5000);
    }

    if (
      session.callState !== prevSession.callState &&
      window.CtxAppConfigurations.lockAudioModeEnabled &&
      session.audioModeLocked &&
      session.callState === "TalkListen"
    ) {
      this.handleMuteParticipant();
    }
  }

  render() {
    const { websocket } = this.props;
    const userId = websocket.userId;

    if (websocket.portalSessionID) {
      if (!this.pollingTimer) {
        this.pollingTimer = setTimeout(this.pollPortal, 10000);
      }
    } else if (this.pollingTimer) {
      clearTimeout(this.pollingTimer);
      this.pollingTimer = null;
    }

    if (userId) {
      if (!this.ws) {
        this.handleWebSocketConnection(userId);
      }
    } else if (this.ws) {
      this.ws.disconnect();
      this.ws = null;
    }

    return null;
  }
}

const mapStateToProps = ({ websocket, session, room, conference }) => ({
  websocket,
  session,
  room,
  conference
});

const mapDispatchToProps = dispatch => ({
  updateParticipants: data => dispatch(updateParticipants(data)),
  updateBufferedParticipants: data =>
    dispatch(updateBufferedParticipants(data)),
  updateConference: data => dispatch(updateConference(data)),
  updateUsers: data => dispatch(updateUsers(data)),
  userLogin: data => dispatch(userLogin(data)),
  logoutUser: (history, persistentSpaceName, userRole, vettingConfig) =>
    dispatch(logoutUser(history, persistentSpaceName, userRole, vettingConfig)),
  websocketReconnect: () => dispatch(websocketReconnect()),
  stopSharing: data => dispatch(stopSharing(data)),
  updateChats: data => dispatch(updateChats(data)),
  updateChatReadState: (chatId, senderId, status) =>
    dispatch(updateChatReadState(chatId, senderId, status)),
  updateUserPriority: senderId => dispatch(updateUserPriority(senderId)),
  updateConferenceState: (data, isHostView) =>
    dispatch(updateConferenceState(data, isHostView)),
  sendPortalKeepalive: sessionID => dispatch(sendPortalKeepalive(sessionID)),
  setDataQAState: dataQAState => dispatch(setDataQAState(dataQAState)),
  updateDataQAEntry: dataQAEntry => dispatch(updateDataQAEntry(dataQAEntry)),
  removeDataQAEntry: entryId => dispatch(removeDataQAEntry(entryId)),
  updateNotification: notificationData =>
    dispatch(updateNotification(notificationData)),
  setSharingUserId: userId => dispatch(setSharingUserId(userId)),
  setHasHostJoined: () => dispatch(setHasHostJoined()),
  setUserID: userID => dispatch(setUserID(userID)),
  setVideoInput: videoInput => dispatch(setVideoInput(videoInput)),
  setWebCamOn: () => dispatch(setWebCamOn()),
  setWebCamOff: () => dispatch(setWebCamOff()),
  handleParticipantApiRequest: (partyId, userId, apiEndpoint) =>
    dispatch(handleParticipantApiRequest(partyId, userId, apiEndpoint)),
  login: (formValues, callback) =>
    dispatch(loginUser(formValues)).then(response => callback(response)),
  setConnectPopperExpansion: payload =>
    dispatch(setConnectPopperExpansion(payload)),
  setCMDialInToken: data => dispatch(setCMDialInToken(data)),
  addTranscript: data => dispatch(addTranscript(data)),
  setPartialTranscript: data => dispatch(setPartialTranscript(data)),
  addTranslation: translation => dispatch(addTranslation(translation)),
  setPartialTranslation: translation =>
    dispatch(setPartialTranslation(translation))
});

export default withRoomContext(
  connect(mapStateToProps, mapDispatchToProps)(WebSocketHandler)
);
