export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const AUTHENTICATE_USER_FAIL = "AUTHENTICATE_USER_FAIL";
export const CLEAR_LOGIN_ERROR = "CLEAR_LOGIN_ERROR";
export const USER_LOGOUT = "USER_LOGOUT";
export const SET_USER_ID = "SET_USER_ID";
export const CONFERENCE_UPDATE = "CONFERENCE_UPDATE";
export const PARTICIPANTS_UPDATE = "PARTICIPANTS_UPDATE";
export const PARTICIPANTS_BUFFERED_UPDATE = "PARTICIPANTS_BUFFERED_UPDATE";
export const PARTICIPANTS_SORT = "PARTICIPANTS_SORT";
export const USERS_UPDATE = "USERS_UPDATE";
export const DATA_CONF_ID_UPDATE = "DATA_CONF_ID_UPDATE";
export const CALL_STATE_UPDATE = "CALL_STATE_UPDATE";
export const SET_TIMEOUT = "SET_TIMEOUT";
export const PARTICIPANT_LIST_SHOW = "PARTICIPANT_LIST_SHOW";
export const PARTICIPANT_LIST_HIDE = "PARTICIPANT_LIST_HIDE";
export const PARTICIPANT_LIST_EXPAND = "PARTICIPANT_LIST_EXPAND";
export const PARTICIPANT_LIST_COLLAPSE = "PARTICIPANT_LIST_COLLAPSE";
export const WEBSOCKET_RECONNECT = "WEBSOCKET_RECONNECT";
export const INTERNAL_DRAWER_HIDE = "INTERNAL_DRAWER_HIDE";
export const INTERNAL_DRAWER_SHOW_PARTICIPANT_LIST =
  "INTERNAL_DRAWER_SHOW_PARTICIPANT_LIST";
export const INTERNAL_DRAWER_SHOW_CHAT = "INTERNAL_DRAWER_SHOW_CHAT";
export const INTERNAL_DRAWER_SHOW_VIDEO = "INTERNAL_DRAWER_SHOW_VIDEO";
export const INTERNAL_DRAWER_SHOW_CONFERENCE_PREFERENCES =
  "INTERNAL_DRAWER_SHOW_CONFERENCE_PREFERENCES";
export const INTERNAL_DRAWER_SHOW_TRANSCRIPT =
  "INTERNAL_DRAWER_SHOW_TRANSCRIPT";
export const WINDOW_RESIZE = "WINDOW_RESIZE";
export const LANGUAGE_UPDATE = "LANGUAGE_UPDATE";
export const THEME_UPDATE = "THEME_UPDATE";
export const STOP_SHARING = "STOP_SHARING";
export const CHATS_UPDATE = "CHATS_UPDATE";
export const CHAT_READ_STATE_UPDATE = "CHAT_READ_STATE_UPDATE";
export const USER_PRIORITY_UPDATE = "USER_PRIORITY_UPDATE";
export const USER_PRIORITY_AND_SELECTED_USER_UPDATE =
  "USER_PRIORITY_AND_SELECTED_USER_UPDATE";
export const USERID_UPDATE_FOR_CHAT_COUNT = "USERID_UPDATE_FOR_CHAT_COUNT";
export const CONFERENCE_STATE_UPDATE = "CONFERENCE_STATE_UPDATE";
export const UPDATE_WEBRTC_CALL_STATE = "UPDATE_WEBRTC_CALL_STATE";
export const HIDE_SETTINGS_MENU = "HIDE_SETTINGS_MENU";
export const HIDE_CONFERENCE_PREFERENCES_MENU =
  "HIDE_CONFERENCE_PREFERENCES_MENU";
export const SHOW_SETTINGS_MENU = "SHOW_SETTINGS_MENU";
export const SET_CONTROLS_TAB_INDEX = "SET_CONTROLS_TAB_INDEX";
export const SET_EXTENSION_AVAILABLE = "SET_EXTENSION_AVAILABLE";
export const SET_CM_LOGGED_IN = "SET_CM_LOGGED_IN";
export const SHOW_CONFERENCE_PREFERENCES_MENU =
  "SHOW_CONFERENCE_PREFERENCES_MENU";
export const SET_AUDIO_INPUT = "SET_AUDIO_INPUT";
export const SET_AUDIO_OUTPUT = "SET_AUDIO_OUTPUT";
export const SET_SHARE_POPPER_EXPANSION = "SET_SHARE_POPPER_EXPANSION";
export const SET_CONNECT_POPPER_EXPANSION = "SET_CONNECT_POPPER_EXPANSION";
export const SET_PERSISTENT_SPACE_AND_CONFIGS =
  "SET_PERSISTENT_SPACE_AND_CONFIGS";
export const LOGIN_PORTAL_USER = "LOGIN_PORTAL_USER";
export const LOGIN_PORTAL_USER_FAIL = "LOGIN_PORTAL_USER_FAIL";
export const AUTHENTICATE_PORTAL_USER = "AUTHENTICATE_PORTAL_USER";
export const AUTHENTICATE_PORTAL_USER_FAIL = "AUTHENTICATE_PORTAL_USER_FAIL";
export const LOGOUT_PORTAL_USER = "LOGOUT_PORTAL_USER";
export const PORTAL_SELECT_VIEW = "PORTAL_SELECT_VIEW";
export const PORTAL_UPDATE_REPORT_CONFERENCE =
  "PORTAL_UPDATE_REPORT_CONFERENCE";
export const PORTAL_UPDATE_REPORT_USER = "PORTAL_UPDATE_REPORT_USER";
export const PORTAL_UPDATE_REPORT_FEATURE_ACTION =
  "PORTAL_UPDATE_REPORT_FEATURE_ACTION";
export const PORTAL_UPDATE_CONFERENCE_RECORDINGS =
  "PORTAL_UPDATE_CONFERENCE_RECORDINGS";
export const PORTAL_REPORT_DATE_FROM = "PORTAL_REPORT_DATE_FROM";
export const PORTAL_REPORT_DATE_TO = "PORTAL_REPORT_DATE_TO";
export const PORTAL_RECORDING_DATE_FROM = "PORTAL_RECORDING_DATE_FROM";
export const PORTAL_RECORDING_DATE_TO = "PORTAL_RECORDING_DATE_TO";
export const PORTAL_DOWNLOAD_REPORT_DIALOG_VISIBLE =
  "PORTAL_DOWNLOAD_REPORT_DIALOG_VISIBLE";
export const UPDATE_PORTAL_USER = "UPDATE_PORTAL_USER";
export const SHAKE_CONF_SECURE_ICON = "SHAKE_CONF_SECURE_ICON";
export const UPDATE_CURRENT_SELECTED_USER = "UPDATE_CURRENT_SELECTED_USER";
export const SET_TALKER_INDICATOR_OPTION = "SET_TALKER_INDICATOR_OPTION";
export const SET_TALKER_INDICATOR_MAX_COUNT = "SET_TALKER_INDICATOR_MAX_COUNT";
export const SET_IS_LOGIN_FORM_SUBMITTING = "SET_IS_LOGIN_FORM_SUBMITTING";
export const SET_MAIN_ROOM = "SET_MAIN_ROOM";
export const RESET_MAIN_ROOM = "RESET_MAIN_ROOM";
export const SET_SHARING_USER_ID = "SET_SHARING_USER_ID";
export const SET_VIDEO_INPUT = "SET_VIDEO_INPUT";
export const SET_BLUR = "SET_BLUR";
export const SET_WEBCAM_ON = "SET_WEBCAM_ON";
export const SET_WEBCAM_OFF = "SET_WEBCAM_OFF";
export const SET_SELFVIEW_ON = "SET_SELFVIEW_ON";
export const SET_SELFVIEW_OFF = "SET_SELFVIEW_OFF";
export const SET_AUDIO_ON = "SET_AUDIO_ON";
export const SET_AUDIO_OFF = "SET_AUDIO_OFF";
export const SET_CHANGING_WEBCAM = "SET_CHANGING_WEBCAM";
export const SET_UPDATING_VIDEO_INPUT = "SET_UPDATING_VIDEO_INPUT";
export const SET_WEBCAM_BUTTON_ENABLED = "SET_WEBCAM_BUTTON_ENABLED";
export const SET_FULL_SCREEN_MODE = "SET_FULL_SCREEN_MODE";
export const SET_HEADER_BARS_VISIBILITY = "SET_HEADER_BARS_VISIBILITY";
export const SET_HAMBURG_MENU_STATE = "SET_HAMBURG_MENU_STATE";
export const SET_LOGOUT_MODAL_STATE = "SET_LOGOUT_MODAL_STATE";
export const SET_MORE_POPPER_EXPANSION = "SET_MORE_POPPER_EXPANSION";
export const SET_AUDIO_POPPER_EXPANSION = "SET_AUDIO_POPPER_EXPANSION";
export const SET_SIGNAL_OPERATOR_POPPER_EXPANSION =
  "SET_SIGNAL_OPERATOR_POPPER_EXPANSION";
export const SET_HAS_HOST_JOINED = "SET_HAS_HOST_JOINED";
export const SET_IS_JOINED_TO_WAITING_ROOM = "SET_IS_JOINED_TO_WAITING_ROOM";
export const SET_DIAL_IN_TOKEN = "SET_DIAL_IN_TOKEN";
export const ADD_TRANSCRIPT = "ADD_TRANSCRIPT";
export const SET_PARTIAL_TRANSCRIPT = "SET_PARTIAL_TRANSCRIPT";
export const ADD_TRANSLATION = "ADD_TRANSLATION";
export const SET_PARTIAL_TRANSLATION = "SET_PARTIAL_TRANSLATION";
export const SET_TRANSLATION_LANGUAGE_INDEX = "SET_TRANSLATION_LANGUAGE_INDEX";
