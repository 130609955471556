import React, { Component } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import {
  Modal,
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
  MenuItem
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { getIntl } from "../../utils";
import { MainPageDummy } from "../pages";
import ChromePicker, { ChromeInputType } from "@uiw/react-color-chrome";
import classNames from "classnames";
import SvgIcon from "../Icons/SvgIcon";
import Stop from "@material-ui/icons/Stop";
import LocalizedText from "../reusable/LocalizedText";
import TextButton from "../reusable/TextButton";
import {
  addNewTheme,
  updateThemeProperties,
  setThemeEditorModalState,
  setThemeEditorTabIndex,
  getGraphicsList
} from "./actions";
import CTXSelect from "../reusable/CTXSelect";
import {
  showNotificationWindow,
  setNotificationLevel,
  NotificationLevel,
  setNotificationType,
  NotificationType
} from "../notification";
import CTXTextField from "../reusable/CTXTextField";

const styles = theme => ({
  root: {
    width: "60vw",
    height: "90vh",
    fontSize: "1vw",
    outline: "none",
    backgroundColor: theme.colors.sideBarBackgroundColor,
    borderRadius: "10px",
    padding: "0px 30px 10px 20px",
    border: `1px solid ${theme.colors.primaryMainColor}`
  },
  center: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  closeHeaderButton: {
    fontSize: "1.2em",
    padding: "14px 14px 8px 8px",
    float: "right"
  },
  closeHeaderIcon: {
    width: ".7em"
  },
  headerIcon: {
    width: "2em",
    marginRight: "15px"
  },
  headerText: {
    margin: "auto",
    color: theme.colors.primaryTextColor
  },
  displayFlexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  displayFlexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  mainContentContainer: {
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    backgroundClip: "content-box"
  },
  detailsContainer: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  detailsRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "1.5em",
    justifyContent: "space-between",
    paddingBottom: "10px",
    paddingRight: "10px",
    color: theme.colors.primaryTextColor
  },
  themeName: {
    padding: "10px 10px 10px 0px",
    color: theme.colors.primaryTextColor
  },
  detailsAndColorPicker: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "5px"
  },
  paletteContainer: {
    margin: "5px",
    border: `5px solid ${theme.colors.secondaryMainColor}`
  },
  hrDiv: {
    borderTop: `4px solid ${theme.colors.primaryTextColor}`
  },
  buttonRightMargin: {
    marginRight: "5px"
  },
  scrollBar: {
    "&::-webkit-scrollbar": {
      width: "8px",
      height: "8px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.colors.secondaryMainColor,
      borderRadius: "4px",
      border: "none"
    },
    "&::-webkit-scrollbar-track-piece": {
      background: theme.colors.sideBarBackgroundColor,
      borderRadius: "4px"
    },
    "&::-webkit-scrollbar-corner": {
      background: theme.colors.sideBarBackgroundColor
    },
    scrollbarColor: `${theme.colors.secondaryMainColor} ${theme.colors.popoverBackgroundColor}`,
    scrollbarWidth: "thin"
  },
  errorTextColor: {
    color: theme.colors.errorTextColor
  },
  textfieldGeneral: {
    paddingLeft: "16px",
    width: "40%",
    marginTop: "8px"
  },
  setAsDefaultCheckBoxLabel: {
    color: theme.colors.primaryTextColor
  },
  setAsDefaultCheckBox: {
    color: theme.colors.primaryMainColor,
    paddingLeft: "14px"
  },
  checkboxOutlineBlankIcon: {
    width: "18px",
    height: "18px"
  },
  checkboxIcon: {
    backgroundColor: theme.colors.primaryMainTextColor,
    borderRadius: "4px",
    width: "18px",
    height: "18px"
  },
  tabContainer: {
    backgroundColor: theme.colors.popoverBackgroundColor
  },
  tabContentContainer: {
    height: "500px",
    backgroundColor: theme.colors.popoverBackgroundColor
  },
  textColor: {
    color: theme.colors.primaryTextColor
  },
  graphicRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px",
    color: theme.colors.primaryTextColor,
    paddingRight: "10px",
    marginBottom: "20px"
  },
  graphicTitleText: {
    paddingLeft: "10px"
  }
});

class ThemeEditorModal extends Component {
  state = {
    themeName: "",
    color1: null,
    color2: null,
    color3: null,
    color4: null,
    isDefault: false,
    backgroundImage: null,
    headerLogo: null,
    bodyLogo: null,
    portalLogo: null,
    portalHeaderLogo: null,
    backgroundImageSelectOpen: false,
    headerLogoSelectOpen: false,
    bodyLogoSelectOpen: false,
    portalLogoSelectOpen: false,
    portalHeaderLogoSelectOpen: false
  };

  componentDidMount() {
    const { selectedTheme } = this.props.portal.theme;
    this.props.getGraphicsList(this.props.session.portalSessionId);

    this.setState({
      color1: selectedTheme.color1,
      color2: selectedTheme.color2,
      color3: selectedTheme.color3,
      color4: selectedTheme.color4,
      backgroundImage: selectedTheme.backgroundImage,
      headerLogo: selectedTheme.headerLogo,
      bodyLogo: selectedTheme.bodyLogo,
      portalLogo: selectedTheme.portalLogo,
      portalHeaderLogo: selectedTheme.portalHeaderLogo
    });
  }

  getColorRow = (colorTitle, colorValue) => {
    const { classes } = this.props;
    return (
      <div className={classes.detailsRow}>
        <LocalizedText value={colorTitle} variant="subtitle2" noWrap={true} />
        <Stop style={{ color: colorValue }} />
      </div>
    );
  };

  getGraphicRow = (
    selectValue,
    graphicTitle,
    open,
    renderValueFn,
    openFn,
    closeFn,
    changeFn
  ) => {
    let menuItems = [];
    const { classes } = this.props;
    const graphicsList = this.props.portal.theme.graphicsList;

    if (graphicsList != null) {
      graphicsList.forEach(fileName =>
        menuItems.push(this.getMenuItem(fileName))
      );
    }

    return (
      <div className={classes.graphicRow}>
        <LocalizedText
          className={classes.graphicTitleText}
          value={graphicTitle}
          variant="h6"
          noWrap={true}
        />
        <CTXSelect
          open={open}
          value={selectValue}
          renderValue={renderValueFn}
          onClose={closeFn}
          onOpen={openFn}
          onChange={changeFn}
          align="right"
          height="large"
        >
          {menuItems}
        </CTXSelect>
      </div>
    );
  };

  getMenuItem = fileName => (
    <MenuItem key={fileName} value={fileName}>
      <Typography variant="subtitle1" noWrap>
        {fileName}
      </Typography>
    </MenuItem>
  );

  handleRenderValueForBackgroundImage = () => (
    <Typography variant="subtitle1" noWrap>
      {this.state.backgroundImage}
    </Typography>
  );

  handleSelectCloseForBackgroundImage = () => {
    this.setState({ backgroundImageSelectOpen: false });
  };

  handleSelectOpenForBackgroundImage = () => {
    if (this.props.portal.theme.graphicsList.length >= 1) {
      this.setState({ backgroundImageSelectOpen: true });
    }
  };

  handleSelectChangeForBackgroundImage = event => {
    this.setState({ backgroundImage: event.target.value });
  };

  handleRenderValueForHeaderLogo = () => (
    <Typography variant="subtitle1" noWrap>
      {this.state.headerLogo}
    </Typography>
  );

  handleSelectCloseForHeaderLogo = () => {
    this.setState({ headerLogoSelectOpen: false });
  };

  handleSelectOpenForHeaderLogo = () => {
    if (this.props.portal.theme.graphicsList.length >= 1) {
      this.setState({ headerLogoSelectOpen: true });
    }
  };

  handleSelectChangeForHeaderLogo = event => {
    this.setState({ headerLogo: event.target.value });
  };

  handleRenderValueForBodyLogo = () => (
    <Typography variant="subtitle1" noWrap>
      {this.state.bodyLogo}
    </Typography>
  );

  handleSelectCloseForBodyLogo = () => {
    this.setState({ bodyLogoSelectOpen: false });
  };

  handleSelectOpenForBodyLogo = () => {
    if (this.props.portal.theme.graphicsList.length >= 1) {
      this.setState({ bodyLogoSelectOpen: true });
    }
  };

  handleSelectChangeForBodyLogo = event => {
    this.setState({ bodyLogo: event.target.value });
  };

  handleRenderValueForPortalLogo = () => (
    <Typography variant="subtitle1" noWrap>
      {this.state.portalLogo}
    </Typography>
  );

  handleSelectCloseForPortalLogo = () => {
    this.setState({ portalLogoSelectOpen: false });
  };

  handleSelectOpenForPortalLogo = () => {
    if (this.props.portal.theme.graphicsList.length >= 1) {
      this.setState({ portalLogoSelectOpen: true });
    }
  };

  handleSelectChangeForPortalLogo = event => {
    this.setState({ portalLogo: event.target.value });
  };

  handleRenderValueForPortalHeaderLogo = () => (
    <Typography variant="subtitle1" noWrap>
      {this.state.portalHeaderLogo}
    </Typography>
  );

  handleSelectCloseForPortalHeaderLogo = () => {
    this.setState({ portalHeaderLogoSelectOpen: false });
  };

  handleSelectOpenForPortalHeaderLogo = () => {
    if (this.props.portal.theme.graphicsList.length >= 1) {
      this.setState({ portalHeaderLogoSelectOpen: true });
    }
  };

  handleSelectChangeForPortalHeaderLogo = event => {
    this.setState({ portalHeaderLogo: event.target.value });
  };

  handleColor1Change = color => {
    this.setState({ color1: color.hex });
  };

  handleColor2Change = color => {
    this.setState({ color2: color.hex });
  };

  handleColor3Change = color => {
    this.setState({ color3: color.hex });
  };

  handleColor4Change = color => {
    this.setState({ color4: color.hex });
  };

  handleThemeNameChange = event => {
    this.setState({ themeName: event.target.value });
  };

  handleSetAsDefaultChange = event => {
    this.setState({ isDefault: event.target.checked });
  };

  handleBackgroundImageChange = value => {
    this.setState({ backgroundImage: value });
  };

  handleHeaderLogoChange = value => {
    this.setState({ headerLogo: value });
  };

  handleBodyLogoChange = value => {
    this.setState({ bodyLogo: value });
  };

  handlePortalLogoChange = value => {
    this.setState({ portalLogo: value });
  };

  handlePortalHeaderLogoChange = value => {
    this.setState({ portalHeaderLogo: value });
  };

  handleThemeUpdate = () => {
    const { session } = this.props;
    const { selectedThemeId } = this.props.portal.theme;
    const {
      color1,
      color2,
      color3,
      color4,
      backgroundImage,
      headerLogo,
      bodyLogo,
      portalLogo,
      portalHeaderLogo
    } = this.state;
    this.props.updateThemeProperties(
      session.portalSessionId,
      selectedThemeId,
      color1,
      color2,
      color3,
      color4,
      backgroundImage,
      headerLogo,
      bodyLogo,
      portalLogo,
      portalHeaderLogo
    );
    this.handleModalClose();
  };

  handleThemeAdd = () => {
    const { session } = this.props;
    const {
      color1,
      color2,
      color3,
      color4,
      isDefault,
      backgroundImage,
      headerLogo,
      bodyLogo,
      portalLogo,
      portalHeaderLogo
    } = this.state;
    const themeName = this.state.themeName.trim();

    if (themeName === "") {
      this.props.setNotificationLevel(NotificationLevel.ERROR);
      this.props.setNotificationType(NotificationType.THEME_NAME_EMPTY);
      this.props.showNotificationWindow();
    } else {
      let duplicate = false;
      const themes = this.props.portal.theme.themeMap;
      if (themes != null) {
        for (const theme of Object.values(themes)) {
          if (theme.themeName === themeName) {
            duplicate = true;
            this.props.setNotificationLevel(NotificationLevel.ERROR);
            this.props.setNotificationType(NotificationType.THEME_DUPLICATE);
            this.props.showNotificationWindow();
            break;
          }
        }
      }

      if (!duplicate) {
        this.props.addNewTheme(
          session.portalSessionId,
          themeName,
          color1,
          color2,
          color3,
          color4,
          isDefault,
          backgroundImage,
          headerLogo,
          bodyLogo,
          portalLogo,
          portalHeaderLogo
        );
        this.handleModalClose();
      }
    }
  };

  handleModalClose = () => {
    this.props.setThemeEditorModalState(false);
  };

  handleTabOnChange = (event, tabIndex) => {
    this.props.setThemeEditorTabIndex(tabIndex);
  };

  getThemeEditorTabControls = () => {
    const { portal, classes } = this.props;
    const { theme } = portal;
    const { themeEditorTabIndex } = theme;

    return (
      <div className={classes.tabContainer}>
        <Tabs
          value={themeEditorTabIndex}
          onChange={this.handleTabOnChange}
          indicatorColor="primary"
        >
          <Tab
            label={
              <LocalizedText
                value="colors"
                variant="caption"
                className={classes.textColor}
              />
            }
          />
          <Tab
            label={
              <LocalizedText
                value="graphics"
                variant="caption"
                className={classes.textColor}
              />
            }
          />
        </Tabs>
      </div>
    );
  };

  getTabContentControls = () => {
    const { portal } = this.props;
    const { theme } = portal;
    const { themeEditorTabIndex } = theme;

    let tabComponent = this.getThemeColorsControl();
    if (themeEditorTabIndex === 1) {
      tabComponent = this.getThemeGraphicsControl();
    }
    return tabComponent;
  };

  getThemeColorsControl = () => {
    const { classes } = this.props;
    const { color1, color2, color3, color4 } = this.state;
    return (
      <div className={classNames(classes.detailsContainer, classes.scrollBar)}>
        <div className={classes.detailsAndColorPicker}>
          {this.getColorRow("color1Title", color1)}
          <ChromePicker
            color={color1}
            inputType={ChromeInputType.HEXA}
            onChange={this.handleColor1Change}
          />
        </div>
        <div className={classes.detailsAndColorPicker}>
          {this.getColorRow("color2Title", color2)}
          <ChromePicker
            color={color2}
            inputType={ChromeInputType.HEXA}
            onChange={this.handleColor2Change}
          />
        </div>
        <div className={classes.detailsAndColorPicker}>
          {this.getColorRow("color3Title", color3)}
          <ChromePicker
            color={color3}
            inputType={ChromeInputType.HEXA}
            onChange={this.handleColor3Change}
          />
        </div>
        <div className={classes.detailsAndColorPicker}>
          {this.getColorRow("color4Title", color4)}
          <ChromePicker
            color={color4}
            inputType={ChromeInputType.HEXA}
            onChange={this.handleColor4Change}
          />
        </div>
      </div>
    );
  };

  getThemeGraphicsControl = () => {
    const { classes } = this.props;
    const {
      backgroundImage,
      headerLogo,
      bodyLogo,
      portalLogo,
      portalHeaderLogo,
      backgroundImageSelectOpen,
      headerLogoSelectOpen,
      bodyLogoSelectOpen,
      portalLogoSelectOpen,
      portalHeaderLogoSelectOpen
    } = this.state;
    return (
      <div className={classNames(classes.displayFlexColumn, classes.scrollBar)}>
        {this.getGraphicRow(
          backgroundImage,
          "backgroundImage",
          backgroundImageSelectOpen,
          this.handleRenderValueForBackgroundImage,
          this.handleSelectOpenForBackgroundImage,
          this.handleSelectCloseForBackgroundImage,
          this.handleSelectChangeForBackgroundImage
        )}
        {this.getGraphicRow(
          headerLogo,
          "headerLogo",
          headerLogoSelectOpen,
          this.handleRenderValueForHeaderLogo,
          this.handleSelectOpenForHeaderLogo,
          this.handleSelectCloseForHeaderLogo,
          this.handleSelectChangeForHeaderLogo
        )}
        {this.getGraphicRow(
          bodyLogo,
          "bodyLogo",
          bodyLogoSelectOpen,
          this.handleRenderValueForBodyLogo,
          this.handleSelectOpenForBodyLogo,
          this.handleSelectCloseForBodyLogo,
          this.handleSelectChangeForBodyLogo
        )}
        {this.getGraphicRow(
          portalHeaderLogo,
          "portalHeaderLogo",
          portalHeaderLogoSelectOpen,
          this.handleRenderValueForPortalHeaderLogo,
          this.handleSelectOpenForPortalHeaderLogo,
          this.handleSelectCloseForPortalHeaderLogo,
          this.handleSelectChangeForPortalHeaderLogo
        )}
        {this.getGraphicRow(
          portalLogo,
          "portalLogo",
          portalLogoSelectOpen,
          this.handleRenderValueForPortalLogo,
          this.handleSelectOpenForPortalLogo,
          this.handleSelectCloseForPortalLogo,
          this.handleSelectChangeForPortalLogo
        )}
      </div>
    );
  };

  render() {
    const { classes, intl } = this.props;
    const { selectedTheme } = this.props.portal.theme;
    const {
      color1,
      color2,
      color3,
      color4,
      backgroundImage,
      headerLogo,
      bodyLogo
    } = this.state;

    return (
      <Modal open={this.props.open}>
        <div
          className={classNames(
            classes.root,
            classes.center,
            classes.displayFlexColumn
          )}
        >
          <div>
            <IconButton
              onClick={this.handleModalClose}
              className={classes.closeHeaderButton}
              title={intl.formatMessage(getIntl("close"))}
            >
              <SvgIcon iconName="close" className={classes.closeHeaderIcon} />
            </IconButton>
          </div>
          <div className={classes.displayFlexRow}>
            <div className={classes.displayFlexRow}>
              <SvgIcon iconName="themeManager" className={classes.headerIcon} />
              <LocalizedText
                value="themeEditor"
                variant="h6"
                className={classes.headerText}
              />
              {selectedTheme.id === 0 ? (
                <div className={classes.displayFlexRow}>
                  <CTXTextField
                    className={classes.textfieldGeneral}
                    value={this.state.encryption}
                    margin="normal"
                    onChange={this.handleThemeNameChange}
                    inputProps={{
                      maxLength: 50
                    }}
                  />
                  <FormControlLabel
                    classes={{
                      label: classes.setAsDefaultCheckBoxLabel
                    }}
                    control={
                      <Checkbox
                        className={classes.setAsDefaultCheckBox}
                        checked={this.state.isDefault}
                        onChange={this.handleSetAsDefaultChange}
                        color="primary"
                        icon={
                          <CheckBoxOutlineBlankIcon
                            viewBox="3 3 18 18"
                            className={classes.checkboxOutlineBlankIcon}
                          />
                        }
                        checkedIcon={
                          <CheckBoxIcon
                            viewBox="3 3 18 18"
                            className={classes.checkboxIcon}
                          />
                        }
                      />
                    }
                    label={
                      <LocalizedText
                        value="setAsDefaultTheme"
                        variant="body1"
                      />
                    }
                  />
                </div>
              ) : (
                <Typography
                  variant="h6"
                  className={classes.themeName}
                  color="inherit"
                >
                  {": " + selectedTheme.themeName}
                </Typography>
              )}
            </div>
            <div className={classes.displayFlexRow}>
              {selectedTheme.id === 0 ? (
                <TextButton
                  onClick={this.handleThemeAdd}
                  className={classes.buttonRightMargin}
                >
                  <LocalizedText value="save" />
                </TextButton>
              ) : (
                <TextButton
                  onClick={this.handleThemeUpdate}
                  className={classes.buttonRightMargin}
                >
                  <LocalizedText value="confirmChanges" />
                </TextButton>
              )}
              <TextButton onClick={this.handleModalClose} color="error">
                <LocalizedText
                  value="cancel"
                  className={classes.errorTextColor}
                />
              </TextButton>
            </div>
          </div>
          <div className={classes.hrDiv} />
          <div
            className={classNames(
              classes.mainContentContainer,
              classes.scrollBar
            )}
          >
            {this.getThemeEditorTabControls()}
            <div className={classes.tabContentContainer}>
              {this.getTabContentControls()}
            </div>
            <div
              className={classNames(
                classes.paletteContainer,
                classes.scrollBar
              )}
            >
              <MainPageDummy
                color1={color1}
                color2={color2}
                color3={color3}
                color4={color4}
                headerLogo={headerLogo}
                bodyLogo={bodyLogo}
                backgroundImage={backgroundImage}
                imagesLocation={selectedTheme.imagesLocation}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ session, portal }) => ({ session, portal });

const mapDispatchToProps = dispatch => ({
  setThemeEditorModalState: isOpen =>
    dispatch(setThemeEditorModalState(isOpen)),
  updateThemeProperties: (
    sessionId,
    themeId,
    color1,
    color2,
    color3,
    color4,
    backgroundImage,
    headerLogo,
    bodyLogo,
    portalLogo,
    portalHeaderLogo
  ) =>
    dispatch(
      updateThemeProperties(
        sessionId,
        themeId,
        color1,
        color2,
        color3,
        color4,
        backgroundImage,
        headerLogo,
        bodyLogo,
        portalLogo,
        portalHeaderLogo
      )
    ),
  addNewTheme: (
    sessionId,
    themeName,
    color1,
    color2,
    color3,
    color4,
    isDefault,
    backgroundImage,
    headerLogo,
    bodyLogo,
    portalLogo,
    portalHeaderLogo
  ) =>
    dispatch(
      addNewTheme(
        sessionId,
        themeName,
        color1,
        color2,
        color3,
        color4,
        isDefault,
        backgroundImage,
        headerLogo,
        bodyLogo,
        portalLogo,
        portalHeaderLogo
      )
    ),
  setThemeEditorTabIndex: tabIndex =>
    dispatch(setThemeEditorTabIndex(tabIndex)),
  getGraphicsList: sessionId => dispatch(getGraphicsList(sessionId)),
  showNotificationWindow: () => dispatch(showNotificationWindow()),
  setNotificationLevel: level => dispatch(setNotificationLevel(level)),
  setNotificationType: type => dispatch(setNotificationType(type))
});

export default withTheme(
  withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(injectIntl(ThemeEditorModal))
  )
);
