import { getIntl, TitleNewLine } from "../../utils";
import { IconButton } from "@material-ui/core";
import SvgIcon from "./SvgIcon";
import LocalizedText from "../reusable/LocalizedText";
import { handleConferenceApiRequest2 } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useIntl } from "react-intl";

const useStyles = makeStyles(theme => ({
  item: {
    width: "6em",
    textAlign: "center",
    padding: "0px 2px 0px 2px"
  },
  iconButton: {
    width: "64px"
  },
  primaryTextColor: {
    color: theme.colors.primaryTextColor
  },
  secondaryTextColor: {
    color: theme.colors.secondaryTextColor
  }
}));

export default function TranscriptionIcon({ onClick }) {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const userId = useSelector(state => state.session.userId);
  const transcriptionActive = useSelector(
    state => state.session.transcriptionActive
  );

  function handleEnableConferenceTranscription() {
    dispatch(handleConferenceApiRequest2(userId, "start_transcription"));
    onClick();
  }

  function handleDisableConferenceTranscription() {
    dispatch(handleConferenceApiRequest2(userId, "stop_transcription"));
    onClick();
  }

  return (
    <div className={classes.item}>
      <IconButton
        className={classes.iconButton}
        onClick={
          transcriptionActive
            ? handleDisableConferenceTranscription
            : handleEnableConferenceTranscription
        }
        title={
          intl.formatMessage(getIntl("transcription")) +
          TitleNewLine +
          (transcriptionActive
            ? intl.formatMessage(getIntl("clickToStopTranscription"))
            : intl.formatMessage(getIntl("clickToStartTranscription")))
        }
      >
        <SvgIcon
          iconName={transcriptionActive ? "stop" : "transcript"}
          color="active"
        />
      </IconButton>
      <div>
        <LocalizedText
          value={
            transcriptionActive ? "stopTranscription" : "startTranscription"
          }
          variant="subtitle2"
          className={classes.primaryTextColor}
        />
      </div>
    </div>
  );
}
