import React, { Component } from "react";
import { Typography, withStyles, withTheme } from "@material-ui/core";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import LocalizedText from "../reusable/LocalizedText";

const styles = theme => ({
  bannerContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    justifyContent: "center",
    backgroundColor: theme.colors.notificationInfoColor,
    width: "100%"
  }
});

class LicenseExpiringBanner extends Component {
  //function
  earliestExpirationDays = () => {
    const { license } = this.props.portal.license;
    let res = Number.MAX_VALUE;

    if (license && license.valid) {
      const durationDiff =
        (new Date(license.expire.replace(" ", "T")).getTime() -
          new Date().getTime()) /
        1000;

      if (durationDiff <= 0) {
        return -1;
      } else {
        res = Math.min(res, durationDiff);
      }
    }

    return res / 86400;
  };

  render() {
    const { classes } = this.props;

    const expiringDays = Math.floor(this.earliestExpirationDays());

    return (
      expiringDays <= 90 &&
      expiringDays >= 0 && (
        <div className={classes.bannerContainer}>
          <LocalizedText value="licenseExpiringIn" variant="body2" inline />
          &nbsp;
          <Typography color="inherit" variant="body2" inline>
            {expiringDays}
          </Typography>
          &nbsp;
          <LocalizedText value="days" variant="body2" inline />
        </div>
      )
    );
  }
}

const mapStateToProps = ({ portal }) => ({ portal });

export default withTheme(
  withStyles(styles)(
    injectIntl(connect(mapStateToProps)(LicenseExpiringBanner))
  )
);
