import {
  INTERNAL_DRAWER_HIDE,
  INTERNAL_DRAWER_SHOW_PARTICIPANT_LIST,
  INTERNAL_DRAWER_SHOW_CHAT,
  INTERNAL_DRAWER_SHOW_VIDEO,
  INTERNAL_DRAWER_SHOW_CONFERENCE_PREFERENCES,
  INTERNAL_DRAWER_SHOW_TRANSCRIPT,
  USER_LOGIN,
  USER_LOGOUT,
  SET_TRANSLATION_LANGUAGE_INDEX
} from "../actions/types";
import { InternalDrawerState } from "../components/layouts/internalDrawerStates";

const initialState = {
  drawer: InternalDrawerState.HIDDEN,
  translationLanguageIndex: 0
};

export default function internalDrawerReducer(state = initialState, action) {
  switch (action.type) {
    case INTERNAL_DRAWER_HIDE:
      return { ...state, drawer: InternalDrawerState.HIDDEN };
    case INTERNAL_DRAWER_SHOW_PARTICIPANT_LIST:
      return { ...state, drawer: InternalDrawerState.PARTICIPANT_LIST };
    case INTERNAL_DRAWER_SHOW_CHAT:
      return { ...state, drawer: InternalDrawerState.CHAT };
    case INTERNAL_DRAWER_SHOW_VIDEO:
      return { ...state, drawer: InternalDrawerState.VIDEO };
    case INTERNAL_DRAWER_SHOW_CONFERENCE_PREFERENCES:
      return {
        ...state,
        drawer: InternalDrawerState.CONFERENCE_PREFERENCES
      };
    case INTERNAL_DRAWER_SHOW_TRANSCRIPT:
      return { ...state, drawer: InternalDrawerState.TRANSCRIPT };
    case SET_TRANSLATION_LANGUAGE_INDEX:
      return { ...state, translationLanguageIndex: action.payload };
    case USER_LOGIN:
      switch (action.payload.expandList) {
        case "participant":
          return {
            ...initialState,
            drawer: InternalDrawerState.PARTICIPANT_LIST
          };
        case "chat":
          return { ...initialState, drawer: InternalDrawerState.CHAT };
        case "video":
          return { ...initialState, drawer: InternalDrawerState.VIDEO };
        default:
          return initialState;
      }
    case USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
}
