import { useRef, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { Typography, List, MenuItem } from "@material-ui/core";
import LocalizedText from "../reusable/LocalizedText";
import CTXSelect from "../reusable/CTXSelect";
import classNames from "classnames";
import { useIntl } from "react-intl";
import { selectTranscript, translationSelectors } from "../../selectors";
import TranscriptButton from "./TranscriptButton";
import { languages, getIntl } from "../../utils";
import { setTranslationLanguageIndex } from "../../actions";

const useTranscriptSectionStyles = makeStyles(
  {
    speakersHeading: {
      fontSize: "1.1rem"
    },
    partialContent: {
      color: "#aaa"
    }
  },
  { name: "TranscriptSection" }
);

export function TranscriptSection({
  speakers,
  content,
  partialContent,
  className
}) {
  const classes = useTranscriptSectionStyles();

  return (
    <div className={className}>
      {speakers && (
        <Typography className={classes.speakersHeading} variant="h6">
          {speakers}
        </Typography>
      )}
      <Typography>
        {content}
        {partialContent && (
          <span className={classes.partialContent}>{partialContent}</span>
        )}
      </Typography>
    </div>
  );
}

const useClassesForPopoutStyles = makeStyles(
  {
    classesForPopout: {
      display: "none"
    }
  },
  { name: "ClassesForPopout" }
);

function ClassesForPopout() {
  const classes = useClassesForPopoutStyles();

  return (
    <div className={classes.classesForPopout}>
      <List />
      <MenuItem />
    </div>
  );
}

const useTranscriptStyles = makeStyles(
  theme => ({
    root: {
      display: "flex",
      flexDirection: "column",
      // The minHeight is needed so that the bottom
      // of the transcript div isn't cut off.
      minHeight: 0,
      height: "100%",
      padding: "5px 20px 0 20px",
      color: theme.colors.primaryTextColor
    },
    heading: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "5px"
    },
    buttons: {
      display: "flex",
      gap: "10px"
    },
    selectRow: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      marginBottom: "5px"
    },
    select: {
      flexGrow: 1
    },
    headingDivider: {
      borderTop: `3px solid ${theme.colors.secondaryMainColor}`
    },
    transcript: {
      paddingRight: "8px",
      overflowY: "auto"
    },
    scrollBar: {
      "&::-webkit-scrollbar": {
        width: "8px",
        height: "8px"
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme.colors.secondaryMainColor,
        borderRadius: "4px",
        border: "none"
      },
      "&::-webkit-scrollbar-track-piece": {
        background: theme.colors.sideBarBackgroundColor,
        borderRadius: "4px"
      },
      "&::-webkit-scrollbar-corner": {
        background: theme.colors.sideBarBackgroundColor
      },
      scrollbarColor: `${theme.colors.secondaryMainColor} ${theme.colors.popoverBackgroundColor}`,
      scrollbarWidth: "thin"
    },
    transcriptSection: {
      margin: "8px 0"
    },
    divider: {
      borderTop: "1px solid #808080"
    }
  }),
  { name: "Transcript" }
);

export default function Transcript({
  stylesCopied,
  copyButtonDisplayed = true
}) {
  const classes = useTranscriptStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const languageIndex = useSelector(
    state => state.internalDrawer.translationLanguageIndex
  );
  const conferenceName = useSelector(state => state.session.confName);
  const conferenceStartTime = useSelector(state => state.session.startTime);

  const translationLanguages = [
    ...window.CtxAppConfigurations.transcriptionTranslationLanguages
  ];
  translationLanguages.sort((a, b) =>
    intl
      .formatMessage(getIntl(languages.get(a)))
      .localeCompare(intl.formatMessage(getIntl(languages.get(b))))
  );

  const transcript = useSelector(state => {
    if (languageIndex === 0) {
      return selectTranscript(state, intl);
    } else {
      return translationSelectors.get(translationLanguages[languageIndex - 1])(
        state
      );
    }
  });

  const transcriptRef = useRef(null);
  const nearBottom = useRef(true);

  useEffect(() => {
    transcriptRef.current.scrollTop = transcriptRef.current.scrollHeight;
  }, [stylesCopied, languageIndex]);
  useEffect(() => {
    if (nearBottom.current) {
      transcriptRef.current.scrollTop = transcriptRef.current.scrollHeight;
    }
  }, [transcript]);

  function handleScroll() {
    if (
      transcriptRef.current.scrollHeight -
        transcriptRef.current.scrollTop -
        transcriptRef.current.offsetHeight <
      100
    ) {
      nearBottom.current = true;
    } else {
      nearBottom.current = false;
    }
  }

  function handleLanguageChange(event) {
    dispatch(setTranslationLanguageIndex(event.target.value));
  }

  let languageName = "";
  if (languageIndex > 0) {
    languageName =
      " " +
      intl.formatMessage(
        getIntl(languages.get(translationLanguages[languageIndex - 1]))
      );
  }

  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        <LocalizedText value="transcript" variant="h6" />
        <div className={classes.buttons}>
          {copyButtonDisplayed && (
            <TranscriptButton type="copy" transcript={transcript}>
              <LocalizedText value="copy" />
            </TranscriptButton>
          )}
          <TranscriptButton
            type="download"
            filename={
              conferenceName +
              " " +
              new Date(conferenceStartTime).toLocaleString("en-US", {
                timeZoneName: "short"
              }) +
              languageName +
              ".txt"
            }
            transcript={transcript}
          >
            <LocalizedText value="download" />
          </TranscriptButton>
        </div>
      </div>
      {translationLanguages.length > 0 && (
        <div className={classes.selectRow}>
          <LocalizedText value="translation" variant="body1" />
          <CTXSelect
            value={languageIndex}
            onChange={handleLanguageChange}
            height="large"
            className={classes.select}
          >
            <MenuItem value={0}>{intl.formatMessage(getIntl("none"))}</MenuItem>
            {translationLanguages.map((language, index) => (
              <MenuItem key={index} value={index + 1}>
                {intl.formatMessage(getIntl(languages.get(language)))}
              </MenuItem>
            ))}
          </CTXSelect>
        </div>
      )}
      <div className={classes.headingDivider} />
      <div
        ref={transcriptRef}
        className={classNames(classes.transcript, classes.scrollBar)}
        onScroll={handleScroll}
      >
        {transcript.map((section, index) => (
          <Fragment key={index}>
            <TranscriptSection
              speakers={section.speakers}
              content={section.content}
              partialContent={section.partialContent}
              className={classes.transcriptSection}
            />
            {languageIndex === 0 && index < transcript.length - 1 && (
              <div className={classes.divider} />
            )}
          </Fragment>
        ))}
      </div>
      <ClassesForPopout />
    </div>
  );
}
