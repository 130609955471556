import { createSelector } from "reselect";
import { parseTranscript, parseTranslation } from "./utils";

export const selectPeers = createSelector(
  [state => state.peers, state => state.participants.displayedUsers],
  (peers, displayedUsers) => {
    const peersArray = [];
    for (const user of displayedUsers) {
      const peer = peers[user];
      if (peer) {
        peersArray.push(peer);
      }
    }
    return peersArray;
  }
);

export const selectTranscript = createSelector(
  [
    state => state.session.transcript,
    state => state.session.partialTranscript,
    (state, intl) => intl
  ],
  parseTranscript
);

const makeSelectTranslation = language => {
  return createSelector(
    [
      state => state.session.translations[language],
      state => state.session.partialTranslations[language]
    ],
    (translations, partialTranslations) => {
      if (!translations) {
        translations = [];
      }
      if (!partialTranslations) {
        partialTranslations = [];
      }
      return parseTranslation(translations, partialTranslations);
    }
  );
};

export const translationSelectors = new Map();
for (const language of window.CtxAppConfigurations
  .transcriptionTranslationLanguages) {
  translationSelectors.set(language, makeSelectTranslation(language));
}
