import LocalizedText from "../reusable/LocalizedText";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    color: theme.colors.disconnectIconColor,
    margin: "10px 0px 10px 0px"
  }
}));

export default function ConferenceTranscriptionIndicator() {
  const classes = useStyles();
  const transcriptionActive = useSelector(
    state => state.session.transcriptionActive
  );
  const quickStart = useSelector(state => state.conference.quickStart);
  const hasHostJoined = useSelector(state => state.session.hasHostJoined);

  return (
    transcriptionActive &&
    (quickStart || hasHostJoined) && (
      <div className={classes.root}>
        <LocalizedText value="transc" variant="subtitle2" />
      </div>
    )
  );
}
